import React from 'react'

const DeliveryPrefs = (props) => {

    return (
        <div>
            <div className="form-group">
                <textarea className="form-control" placeholder="Preferred delivery / pickup time? Please specify" name="deliveryTime" rows={2} onChange={(e)=>props.changeHandler(e)} value={props.deliveryTime} />
            </div>
        </div>
    )
}

export default DeliveryPrefs