import React from 'react';
import './App.css';
import CartContainer from './CartContainer';
import Aarbei from './Aarbei';

function App() {
  return (
    <div>
      <CartContainer />
      <div className="spacer"></div>
      <Aarbei />
    </div>
    
  );
}

export default App;
