import React, { Component } from 'react'

class Categories extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        //console.log(nextProps.selectedCategory.id, this.props.selectedCategory.id)
        if (nextProps.selectedCategory.id === this.props.selectedCategory.id) {
            if ((typeof (this.props.selectedCategory.id) === "undefined")) {
                return true
            } else {
                return false
            }

        } else {
            return true
        }
    }

    render() {
        //console.log('render categories')
        return (
            <div style={{ "textAlign": "center" }}>
                {this.props.selectedCategory.id ?

                    <div>
                        <button className="btn btn-secondary categoryBtn btn-lg" style={{ "textAlign": "center" }} onClick={() => this.props.setCategory(0)}>
                            <i className="fas fa-chevron-left"></i> Back
                        </button>
                        {this.props.categories.map(e => {
                            return (<div key={e.id} style={{ "padding": "1px", "display": "inline-block" }}>
                                {this.props.selectedCategory.id === e.id ?
                                    <button className="btn btn-info categoryBtn btn-lg" style={{ "textAlign": "center" }} onClick={() => this.props.setCategory(e.id)}>
                                        <i className="fas fa-cookie-bite"></i> {e.title}
                                    </button>
                                    :
                                    null
                                }
                            </div>)
                        })
                        }
                        
                    </div>
                    :
                    
                        this.props.categories.map(e => {
                            return (<div key={e.id} style={{ "padding": "1px", "display": "inline-block" }}>
                                {this.props.selectedCategory.id === e.id ?
                                    <button className="btn btn-info categoryBtn" style={{ "textAlign": "center" }} onClick={() => this.props.setCategory(e.id)}>
                                        <i className="fas fa-cookie-bite"></i> {e.title}
                                    </button>
                                    :
                                    <button className="btn btn-secondary categoryBtn" style={{ "textAlign": "center" }} onClick={() => this.props.setCategory(e.id)}>
                                        <i className="fas fa-cookie-bite"></i> {e.title}
                                    </button>
                                }
                            </div>)
                        })
                    
                }
            </div >

        )
    }

}

export default Categories