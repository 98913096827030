import React from 'react'

const ItemsTable = (props) => {

    

    return (
        <table className="table table-sm table-borderless">
            <thead style={{"backgroundColor": "#ffffff"}}>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Item</th>
                    <th scope="col" style={{"textAlign":"center"}}>Price</th>
                    <th scope="col" style={{"textAlign":"center"}}>Qty</th>
                </tr>
            </thead>
            <tbody>
                {
                props.cartItems.map(e => {
                    return (
                        <tr key={e.id}>
                            <td><button className="btn btn-warning btn-sm" onClick={()=>props.rfc(e.id)}><i className="fas fa-times"></i></button></td>
                            <td>{e.title}</td>
                            <td style={{"textAlign":"center"}}>R {e.price}</td>
                            <td style={{"textAlign":"center"}}>
                                <input type="number" pattern='[0-9]'
                                        className="form-control" 
                                        name={e.id} 
                                        value={e.qty} 
                                        step={1}
                                        min={1}
                                        onChange={(e)=>props.changeQtyHandler(e)} 
                                        style={{"width":"50px","textAlign":"center","margin":"auto"}}
                                />
                            </td>
                        </tr>
                    )
                })}

                <tr><td></td>
                    <td style={{"textAlign":"right"}}><strong>Total</strong></td>
                    <td style={{"textAlign":"center"}}><strong>R {props.totalsum}</strong></td>
                    <td style={{"textAlign":"center"}}><strong>{props.totalqty} item(s)</strong></td>
                </tr>
            </tbody>
            

        </table>
    )
}

export default ItemsTable