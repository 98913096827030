import React from 'react'

const Customer = (props) => {

    return (
        <div>
            <div className="form-group">
                <input className="form-control" id="naam" type="text" required value={props.customer.naam} placeholder="Name" name="naam" onChange={(e)=>props.changeHandler(e)} />
            </div>
            <div className="form-group">
                {props.customer.webid > 0 ? 
                    <input className="form-control" id="eposadres" type="email" required disabled minLength="5" value={props.customer.eposadres} placeholder="E-mail" name="eposadres" onChange={(e)=>props.changeHandler(e)} />
                    : 
                    <input className="form-control" id="eposadres" type="email" required minLength="5" value={props.customer.eposadres} placeholder="E-mail" name="eposadres" onChange={(e)=>props.changeHandler(e)} />
                }
                
            </div>
            <div className="form-group">
                <input className="form-control" id="scell" type="text" required value={props.customer.scell} placeholder="Cell nr" name="scell" onChange={(e)=>props.changeHandler(e)} />
            </div>
            <div className="form-group">
                <textarea className="form-control" id="sdeladres" name="sdeladres" placeholder="Clanwilliam delivery address" onChange={(e)=>props.changeHandler(e)} value={props.customer.sdeladres} />
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" id="rememberMe" checked name="rememberMe" onChange={(e)=>props.changeHandler(e)}  />
                <label className="form-check-label" htmlFor="rememberMe">
                    Remember me
                </label>
            </div>
        </div>
    )
}

export default Customer