import React from 'react'

const Welcome = (props) => {
    return (
        <div className="productsContainer">
            
                {props.msg? 
                    <div className="card shadow-sm">
                        <img src="https://shop.velskoendraai.co.za/banner.jpg" alt="" className="card-img-top" style={{ "width": "100%" }} />
                        <div className="card-body">
                            <h2 style={{"color":"#B8675F"}}>Thank you for supporting local business and tourism</h2>
                            <h3>{props.msg}</h3>
                            <p>An e-mail confirming your order was sent to you.</p>
                            <p>We will be in contact shortly!</p>
                        </div>
                    </div>
                :
                    <div>
                        <h2 style={{"color":"#B8675F","textAlign":"center"}}>CLANWILLIAM | Welcome to Velskoendraai Shop and Order Online</h2>
                        <p style={{"textAlign":"center"}}><strong>It's easy</strong> - just pick items from our categorized menu, go to checkout, fill out your details and submit.</p>
                        <img src="welcome.jpg" alt="product" style={{ "width": "100%" }} />
                    </div>
                }
        </div>

    )
}

export default Welcome