import React, { Component } from 'react';
import Header from './Header'
import Categories from './Categories'
import Welcome from './Welcome'
import CostItems from './CostItems'
import axios from 'axios'

//const site_url = "http://localhost";
const imagesUrl = "https://shop.velskoendraai.co.za"
const site_url = "https://menu.velskoendraai.co.za";
const api_root = site_url + "/_247blipcart_api/";
const submitOrder_file = "submit_order.php";
const customer_file = "customer.php";

const validEmailRegex =
    RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i);

class CartContainer extends Component {
    constructor(props) {
        super(props)
        this.myCartRef = React.createRef()
        this.state = {
            restaurant: "Velskoendraai MENU",
            fullmenu: [],
            selectedCategory: {},
            items: [],
            categories: [],
            cartItems: [],
            hideCart: true,
            customer: {
                webid: 0,
                eposadres: '',
                naam: '',
                sdeladres: '',
                scell: '',
                rememberMe: true
            },
            extra: '',
            deliveryTime: '',
            validFormInputToSubmit: false,
            orderSubmitted: false,
            msg: ''
        }
    }

    checkInputFields = (customer) => {
        if (customer.naam.length < 1) {
            document.getElementById('naam').style="background-color:#E4A392"
            return false
        } else {
            document.getElementById('naam').style="background-color:#ffffff"
        }
        if (!validEmailRegex.test(customer.eposadres)) {
            document.getElementById('eposadres').style="background-color:#E4A392"
            return false
        } else {
            document.getElementById('eposadres').style="background-color:#ffffff"
        }

        if (customer.scell.length < 9) {
            document.getElementById('scell').style="background-color:#E4A392"
            return false
        } else {
            document.getElementById('scell').style="background-color:#ffffff"
        }
        if (customer.sdeladres.length < 3) {
            document.getElementById('sdeladres').style="background-color:#E4A392"
            return false
        } else {
            document.getElementById('sdeladres').style="background-color:#ffffff"
        }
        return true
    }

    changeQtyHandler = (event) => {
        const { name, value } = event.target
        //if (value >= 1) {
            let allItemsExceptBelow = this.state.cartItems.filter(e => e.id !== name)
            let cartItemToChange = this.state.cartItems.filter(e => e.id === name)

            if (cartItemToChange.length === 1) {
                cartItemToChange[0].qty = value
                allItemsExceptBelow.push(cartItemToChange[0])

                let finalArray = allItemsExceptBelow.sort((a, b) => {
                    if (a.poscode > b.poscode) {
                        return 1
                    } else {
                        if (a.poscode < b.poscode) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                })

                this.setState({
                    cartItems: finalArray
                })
            }
        //}



    }

    changeHandler = (event) => {
        const { name, value, type, checked } = event.target

        let formInputsValid = this.checkInputFields(this.state.customer)

        if (type === "checkbox") {
            this.setState({ [name]: checked })
        } else {
            if (name === "extra" || name==="deliveryTime") {
                this.setState({
                    [name]: value
                })
            } else {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        [name]: value
                    },
                    validFormInputToSubmit: formInputsValid
                })
            }
        }

    }

    atc = (e) => {

        let newCartItems = this.state.cartItems
        

        if (!newCartItems.includes(e)) {
            newCartItems.push(e)
            let finalArray = newCartItems.sort((a, b) => {
                if (a.poscode > b.poscode) {
                    return 1
                } else {
                    if (a.poscode < b.poscode) {
                        return -1
                    } else {
                        return 0
                    }
                }
            })
            this.setState({
                cartItems: finalArray
            })
        }
    }
    rfc = (id) => {

        let newCartItems = this.state.cartItems.filter(e => e.id !== id)

        let finalArray = newCartItems.sort((a, b) => {
            if (a.poscode > b.poscode) {
                return 1
            } else {
                if (a.poscode < b.poscode) {
                    return -1
                } else {
                    return 0
                }
            }
        })

        this.setState({
            cartItems: finalArray
        })

    }

    toggleCart = () => {
        let prevState = this.state.hideCart
        
        this.setState({ hideCart: !prevState })
        
    }

    setCategory = (catid) => {
        let filteredItems = this.state.fullmenu
        let myCategory = this.state.selectedCategory
        myCategory = [{}]

        if (catid !== 0) {
            filteredItems = this.state.fullmenu.filter(e => e.catid === catid)
            myCategory = this.state.categories.filter(e => e.id === catid)
        } 

        this.setState({
            items: filteredItems,
            selectedCategory: myCategory[0]
        })
    }

    submitOrder = () => {

        this.setState({
            orderSubmitted: true
        })

        
        let pm = document.getElementById('paymentMethod').value
        let dm = document.getElementById('deliveryMethod').value

        if (this.state.customer.rememberMe) {
            localStorage.setItem('my_blipcart_email', this.state.customer.eposadres)
        } else {
            localStorage.removeItem('my_blipcart_email');
            localStorage.clear()
        }

        let totalOrderValue = 0
        let delivery = 0

        this.state.cartItems.forEach(element => {
            totalOrderValue = totalOrderValue + (parseInt(element.price) * parseInt(element.qty))
        });

        if (totalOrderValue < 100) { delivery = 10 }

        let keepFormValid = false
        if (this.state.customer.webid > 0 || this.state.rememberMe) { keepFormValid = true }

        axios.post(api_root + submitOrder_file, {
            cartItems: this.state.cartItems,
            customer: this.state.customer,
            extra: this.state.extra,
            totalOrderValue: totalOrderValue,
            delivery: delivery,
            paymentMethod: pm,
            deliveryMethod: dm,
            deliveryTime: this.state.deliveryTime
        })
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        selectedCategory: {},
                        cartItems: [],
                        hideCart: true,
                        extra: '',
                        validFormInputToSubmit: keepFormValid,
                        msg: 'Thank you ' + this.state.customer.naam + ', your order was submitted!',
                        orderSubmitted: false
                    })
                }
            }, (error) => {
                console.log(error);
            });
        

    }

    viewMenuCategories = () => {
        let initFullmenu = []
        let initCategories = []
        axios.get(api_root + '?info=categories')
            .then((res) => {
                initCategories = res.data
            }).then(

            )
            .then(
                axios.get(api_root + '?info=fullmenu')
                    .then((res) => {
                        initFullmenu = res.data
                        this.setState({
                            categories: initCategories,
                            fullmenu: initFullmenu,
                            items: initFullmenu

                        })
                    })
            )
    }

    componentDidMount() {

        let initFullmenu = []
        let initCategories = []

        let eml = localStorage.getItem('my_blipcart_email')
        let initCustomer = {}

        if (eml) {
            let authHeaders = {
                headers: {
                  'Authorization': 'Bearer m0r#ngoXru!t5'
                }
              }
            let data = {eposadres: eml, info: "initCustomer"}
            axios.post(api_root + customer_file,data,authHeaders)
                .then((res) => {
                    
                    if (res.data[0]) {
                        initCustomer = {
                            webid: res.data[0].webid,
                            eposadres: eml,
                            naam: res.data[0].naam,
                            sdeladres: res.data[0].sdeladres,
                            scell: res.data[0].scell,
                            rememberMe: true
                        }
                        this.setState({
                            customer: initCustomer,
                            validFormInputToSubmit: true
                        })
                    }
                })
                .catch(err => console.log(err))
        }

        axios.get(api_root + '?info=categories')
            .then((res) => {
                initCategories = res.data
            }).then(

            )
            .then(
                axios.get(api_root + '?info=fullmenu')
                    .then((res) => {
                        initFullmenu = res.data
                        this.setState({
                            categories: initCategories,
                            fullmenu: initFullmenu,
                            items: initFullmenu

                        })
                    })
            )



    }

    render() {
        return (
            this.state.fullmenu.length > 0 ?
                <div>
                    <Header restaurant={this.state.restaurant}
                        cartItems={this.state.cartItems}
                        hideCart={this.state.hideCart}
                        toggleCart={this.toggleCart}
                        rfc={this.rfc}
                        customer={this.state.customer}
                        changeHandler={this.changeHandler}
                        extra={this.state.extra}
                        paymentMethod={this.state.paymentMethod}
                        submitOrder={this.submitOrder}
                        validFormInputToSubmit={this.state.validFormInputToSubmit}
                        changeQtyHandler={this.changeQtyHandler}
                        myCartRef={this.myCartRef}
                        orderSubmitted={this.state.orderSubmitted}
                    />
                    {this.state.hideCart ?
                    <div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <div className="productsContainer">

                                        {this.state.categories.length > 0 ?
                                            <Categories categories={this.state.categories} setCategory={this.setCategory} selectedCategory={this.state.selectedCategory} />
                                            : 
                                            <button className="btn btn-warning" onClick={()=>this.viewMenuCategories()}>Load Menu</button>    
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    {this.state.selectedCategory.id ?
                                        <CostItems selectedCategory={this.state.selectedCategory} items={this.state.items} atc={this.atc} imgRootUrl={imagesUrl} />
                                        :
                                        <Welcome msg={this.state.msg} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null }
                </div>
                : null
        )
    }
}

export default CartContainer