import React, { Component } from 'react'
import ItemsTable from './ItemsTable'
import Customer from './Customer'
import Extra from './Extra'
import DeliveryPrefs from './DeliveryPrefs';

class CartContents extends Component {

    componentDidMount() {
        this.props.myCartRef.current.scrollTo(0, 0);
        window.scrollTo(0, 0);
    }

    render() {
        let totalsum = 0
        let delivery = 0
        let totalqty = 0
        this.props.cartItems.forEach(element => {
            let parsed = parseInt(element.qty)
            if (isNaN(parsed)) { parsed = 0 }
            totalsum = totalsum + (parseInt(element.price) * parsed)
            totalqty = totalqty + (parsed)
        });

        if (totalsum < 100) {
            delivery = 10
        }

        return (
            <div ref={this.props.myCartRef}>
                <nav className="navbar fixed-top">
                    <div className="container-fluid">
                        <div className="navbar-brand"><strong>My Cart</strong></div>
                        <button className="btn btn-secondary btn-lg" onClick={() => this.props.toggleCart()}>
                            <i className="fas fa-times"></i> Close
                        </button>
                    </div>
                </nav>
                <div className="spacer"></div>

                {this.props.cartItems.length > 0 ?
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-shopping-basket"></i> Your cart</div>
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <ItemsTable cartItems={this.props.cartItems} rfc={this.props.rfc} totalsum={totalsum} totalqty={totalqty} changeQtyHandler={this.props.changeQtyHandler} />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-truck"></i> Delivery</div>
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    {totalsum < 100 ? <p>Delivery of orders below R100: <strong>R {delivery}</strong><br />Orders over R100 - free delivery</p> : <p>Free delivery</p>}
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-candy-cane"></i> Comments / Requests</div>
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <Extra extra={this.props.extra} changeHandler={this.props.changeHandler} />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4 text-left">
                            <div className="mobispacer"></div>
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-user"></i> Your details</div>
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <Customer customer={this.props.customer} changeHandler={this.props.changeHandler} />
                                </div>
                                <div className="card-footer text-sm">
                                    <div><strong>Required fields</strong>: name, e-mail, cell nr, delivery address</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mobispacer"></div>
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-truck"></i> Delivery / Pickup</div>
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <p>Our meals are prepared fresh. Please allow a lead time of 1 hour.</p>
                                    <p style={{ "textAlign": "center" }}>
                                        <select name="deliveryMethod" id="deliveryMethod" className="form-control" style={{ "textAlign": "center" }}>
                                            <option>Please deliver</option>
                                            <option>I'll pickup my order</option>
                                        </select>
                                    </p>
                                    <p>We deliver between 10h00 and 17h00. Last orders by 16h00.</p>
                                    <DeliveryPrefs deliveryTime={this.props.deliveryTime} changeHandler={this.props.changeHandler} />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div className="card shadow-sm">
                                <div className="card-header"><i className="fas fa-smile-wink"></i> Payment</div>
                                <div className="card-body" style={{ "textAlign": "center" }}>
                                    <h3>Total: R {totalsum}<br />Delivery: R {delivery}</h3>
                                    <p style={{ "textAlign": "center" }}>
                                        <select name="paymentMethod" id="paymentMethod" className="form-control" style={{ "textAlign": "center" }}>
                                            <option>Pay by EFT</option>
                                            <option>Pay with SnapScan</option>
                                            <option>Pay by CARD on delivery / pickup</option>
                                            <option>Pay by CASH on delivery / pickup</option>
                                        </select>
                                    </p>
                                    {this.props.validFormInputToSubmit ?
                                        <div>

                                            <p style={{ "textAlign": "center" }}>
                                                {this.props.orderSubmitted ?
                                                    <button className="btn btn-warning btn-lg" disabled>
                                                        <i className="fas fa-thumbs-up"></i> Submitting...
                                                </button>
                                                    :
                                                    <button className="btn btn-warning btn-lg" onClick={() => this.props.submitOrder()}>
                                                        <i className="fas fa-thumbs-up"></i> Submit Order
                                                </button>
                                                }
                                            </p>
                                        </div>
                                        :
                                        <div style={{ "color": "#D5694F" }}><strong>Please complete required fields to submit order</strong></div>
                                    }

                                </div>
                            </div>
                            <div className="spacer"></div>
                        </div>
                    </div>

                    : <h1>Empty cart!</h1>}
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center">

                    </div>
                </div>
            </div>

        )
    }

}
//style={{ "width": "100%", "minHeight": "100px" }}
export default CartContents