import React, { Component } from 'react'

const lastRowRemainder = (actualLength,checkLength) => {
    while (actualLength > checkLength) {
        actualLength = actualLength - checkLength
    } 
    return (checkLength - actualLength)
}
class CostItems extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.selectedCategory.id !== nextProps.selectedCategory.id) {
            return true
        } else {
            return false
        }
    }
    render() {

        let pivot=0
        if (this.props.items.length <=6) {
            pivot = 6 - this.props.items.length
        } else {
            pivot = lastRowRemainder(this.props.items.length,6)
        }

        let fillerArray = []
        for(let i = 0; i<pivot; i++){
            fillerArray.push(i)
        }
        return (
            <div>
                <div className="row productsContainer" style={{"textAlign":"center"}}>

                    {this.props.items.map(e => {
                        return (
                                <div className="col-md" key={e.id}>
                                    <div className="costitem">
                                        <div className="card shadow-sm">
                                            <img src={e.image ? this.props.imgRootUrl+"/Products/" + e.image + "" : "blank.jpg"} alt="product" className="card-img-top card-img-top-special" style={{ "width": "100%" }} />
                                            <div className="card-body">
                                                <div className="card-title" style={{ "fontSize": "100%" }}><strong>{e.title}</strong></div>
                                                <div className="card-title">R {e.price}</div>
                                                {e.desc?
                                                    <div>
                                                        {/*<div className="costitemDesc">{e.desc}</div>*/
                                                        
                                                        }
                                                        <button className="btn btn-outline-default btn-sm" data-toggle="collapse" data-target={"#demo"+e.id}>Read more...</button>
                                                        <div id={"demo"+e.id} className="collapse" style={{"fontSize":"85%"}}>
                                                            {e.desc}
                                                        </div>
                                                        <div>&nbsp;</div>
                                                    </div>
                                                : 
                                                    null 
                                                }
                                                {e.soh === "0" ?
                                                    <div><button className="btn btn-info" onClick={() => this.props.atc(e)}>Add to order</button></div>
                                                :   <div><button className="btn btn-secondary btn-sm" disabled title="Not available or sold out">Not available</button></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {fillerArray.map(e=>{
                        return (<div className="col-md" key={"dud_"+e}>&nbsp;</div>)
                    })}
                    
                </div>
            </div>
        )
    }
}
export default CostItems