import React from 'react'
import CartContents from './CartContents'

const Header = (props) => {

    let btnText = props.cartItems.length + ''
    if (!props.hideCart) {
        btnText = 'X'
    }
    return (
        <div>
            {props.hideCart ?
            <nav className="navbar fixed-top">
                <div className="container-fluid">
                    <div className="navbar-brand">{props.restaurant}</div>
                    <div className="float-right">
                        {props.cartItems.length > 0 ?
                            <button className="btn btn-warning btn-lg" onClick={()=>props.toggleCart()}>
                                <i className="fas fa-shopping-basket"></i> {btnText}
                            </button>
                        :   
                            <button className="btn btn-outline-default" style={{"color":"#ffffff"}}>
                                <i className="fas fa-user"></i> Welcome {props.customer.naam}
                            </button>
                        
                        }
                    </div>
                </div>
                
            </nav>
            :
            <div className="container-fluid cartContentsWrapper">
                <CartContents cartItems={props.cartItems} 
                    hideCart={props.hideCart} 
                    toggleCart={props.toggleCart} 
                    rfc={props.rfc} 
                    customer={props.customer} 
                    changeHandler={props.changeHandler}   
                    extra={props.extra}
                    paymentMethod={props.paymentMethod}
                    submitOrder={props.submitOrder}
                    validFormInputToSubmit={props.validFormInputToSubmit}
                    changeQtyHandler={props.changeQtyHandler}
                    myCartRef={props.myCartRef}
                    deliveryTime={props.deliveryTime}
                    orderSubmitted={props.orderSubmitted}
                />
            </div>
            }
        </div>
        
    )
}

export default Header