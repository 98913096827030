import React from 'react'

const Extra = (props) => {

    return (
        <div>
            <div className="form-group">
                <textarea className="form-control" placeholder="Extra sugar? Brown or white bread? Salad or chips?" name="extra" rows={3} onChange={(e)=>props.changeHandler(e)} value={props.extra} />
            </div>
        </div>
    )
}

export default Extra