import React from 'react'

const Aarbei = () => {
    return (
        <div className="fixed-bottom" style={{"padding":"5px","backgroundColor":"#F4EAD5","textAlign":"center","color":"#555555","fontSize":"90%"}}>
            <a href="https://www.aarbei.co.za/" style={{"color":"#444444","textDecoration":"none"}} target="_blank" rel="noopener noreferrer">
                <img src="https://www.aarbei.co.za/branding/aarbei.co.za_gray.png" style={{"height":"50px","verticalAlign":"middle"}} alt="Hosting and Development" />
            </a>
        </div>
    )
}

export default Aarbei